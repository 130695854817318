@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;600;800&display=swap);
@keyframes fade-in-left {
  0% {
      transform: translateX(-200px);
          opacity: 0;
  }
  100% {
      transform: translateX(0);
          opacity: 1;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

a {
  text-decoration: none;
  color: #fff;
}

li {
  list-style: none;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.header-text {
  font-weight: 800;
  animation: fade-in-left 1s ease-in-out .5s both;
}

.header-bar {
  background-color: #010101;
  height: 3px;
  width: 150px;
  animation: fade-in-left 1s ease-in-out 1s both;
}

.about-contents {
    /* background-color: red; */
    width: 70vw;
    margin: 5rem auto;
}

.about__me {
    display: flex;
    grid-gap: 50px;
    gap: 50px;
}

.about__me-img {
    width: 500px;
    text-align: center;
    flex: 1 1;
}

.about__me-img img {
    width: 100%;
    border-radius: 50%;
    border: 3px solid #cc33ff;
    margin-bottom: 20px;
}

.about__me-content {
    padding: 0 20px;
    flex: 2 1;
}

.about__me-who {
    font-weight: 600;
    color: #cc33ff;
}

.about__me-headline {
    font-weight: 700;
    margin-bottom: 30px;
}

.about__me-content p {
    font-size: 1.1rem;
    margin-bottom: 15px;
}

.about__me-certs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.about__me-certs img {
    width: 25%;
    height: 100%;
}

.about__me-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-social {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;
}

.about-social .fab {
    color: #cc33ff;
    padding: 2rem;
}

@media screen and (max-width: 460px) {
    .about-contents {
        /* background-color: red; */
        width: 90vw;
        margin: 2rem auto 0;
    }

    .about__me {
        flex-direction: column;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
    }

    .about__me-img {
        width: 200px;
        margin-top: -40px;
    }

    .about__me-img h5 {
        display: none;
    }

    .about__me-content {
        padding: 0 10px;
        width: 90vw;
    }

    .about__me-content p {
        font-size: .9rem;
        margin-bottom: 10px;
    }

    .about-social {
        width: 100%;
        margin: 0 auto;
    }
    
    .about-social .fab {
        padding: 1rem;
    }

    hr {
        display: none;
    }

    .about__me-btn .btn {
        font-size: .7rem;
    }
}
@keyframes fade-in-left {
    0% {
        transform: translateX(-200px);
            opacity: 0;
    }
    100% {
        transform: translateX(0);
            opacity: 1;
    }
}

.frontend-container {
    background-color: #010101;
    margin: 0 auto;
    color: #fff;
}

.frontend-contents {
    /* background-color: tomato; */
    width: 70vw;
    margin: 0 auto;
    padding: 3rem 0 .2rem;
}

.frontend-contents .header {
    margin-bottom: 10px;
}

.frontend-contents h5 {
    animation: fade-in-left 1s ease-in-out .5s both;
}

.frontend-contents .header-bar {
    background-color: #fff;
}

.gridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    padding: 2rem;
    place-items: center;
    grid-column-gap: 2rem;
    column-gap: 2rem;
    grid-row-gap: 3rem;
    row-gap: 3rem;
    margin-bottom: 0.5rem;
    animation: fade-in-left 1s ease-in-out 1.5s both;
}

.blogCard {
    border-radius: 10px;
    box-shadow: 3px 3px 20px rgba(80, 78, 78, 0.5);
    text-align: center;
    width: 300px;
}

.portImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.titleContent {
    text-align: center;
    z-index: 20;
    width: 100%;
}

.headerThree {
    font-weight: 500;
    letter-spacing: 2px;
    color: #cc33ff;
    padding: 0.5rem 0;
}

hr {
    width: 50px;
    height: 3px;
    margin: 20px auto;
    border: 0;
    background: #d0bb57;
}
.cardInfo {
    width: 100%;
    padding: 0 30px;
    color: #e4e6e7;
    font-size: .8rem;
    line-height: 24px;
    text-align: center;
}

.titleContent {
    text-align: center;
    z-index: 20;
    width: 100%;
}

.tagList {
    display: flex;
    justify-content: space-around;
    padding: .5rem;
}

.tag {
    color: #d8bfbf;
    font-size: 1rem;
}

.utilityList {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
    margin: 1rem 0;
}

.externalLinks {
    color: #fff;
    font-size: 1.6rem;
    padding: 5px 20px;
    background: #cc33ff;
    border-radius: 26px;
    transition: 0.5s;
    font-size: .8rem;
}

.externalLinks:hover {
    background: #8300af;
    color: #fff;
}

@media screen and (max-width: 460px) {
    .frontend-contents {
        /* background-color: tomato; */
        width: 90vw;
    }
}
@keyframes fade-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.graphics-container {
  margin: 0 auto;
  background-color: #eee;
}

.graphics-contents {
  /* background-color: tomato; */
  width: 70vw;
  margin: 0 auto;
  padding: 3rem 0 3rem;
}

.graphics-contents .header {
  margin-bottom: 40px;
}

.graphics-contents h5 {
  animation: fade-in-left 1s ease-in-out .5s both;
}

.angry-grid {
  display: grid;

  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 0.5fr 0.5fr 1fr 1fr;

  grid-gap: 5px;

  gap: 5px;
  height: 90%;
  /* animation: fade-in-left 1s ease-in-out 1.5s both; */
}

#item-0 {
  /* background-color: #769966; */
  background: url(/static/media/dragon.b03403c8.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  grid-row-start: 1;
  grid-column-start: 1;

  grid-row-end: 2;
  grid-column-end: 3;
  height: 250px;
  cursor: pointer;
}
#item-1 {
  /* background-color: #b7998d; */
  background: url(/static/media/rave.05b93bb4.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-row-start: 1;
  grid-column-start: 3;

  grid-row-end: 2;
  grid-column-end: 5;
  cursor: pointer;
}
#item-2 {
  /* background-color: #5679ff; */
  background: url(/static/media/bikerace.bd2a26d5.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-row-start: 1;
  grid-column-start: 5;

  grid-row-end: 3;
  grid-column-end: 6;
  cursor: pointer;
}

#item-4 {
  /* background-color: #9896db; */
  background: url(/static/media/michaelangelo.5179d47a.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-row-start: 2;
  grid-column-start: 4;

  grid-row-end: 3;
  grid-column-end: 5;
  cursor: pointer;
}
#item-5 {
  /* background-color: #b795bb; */
  background: url(/static/media/gel.02f2407b.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-row-start: 2;
  grid-column-start: 2;

  grid-row-end: 3;
  grid-column-end: 4;
  cursor: pointer;
}
#item-6 {
  /* background-color: #cb9eb7; */
  background: url(/static/media/wowPoster.d2d0e8db.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-row-start: 2;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 2;
  cursor: pointer;
}


@media screen and (max-width: 460px) {
  .graphics-contents {
    /* background-color: tomato; */
    width: 90vw;
    margin: 0 auto;
    padding: 3rem 0 3rem;
  }

  #item-0 {
    background-position: left;
    height: 150px;
  }

  #item-1 {
    background-position: 70%;
  }

  #item-4 {
    /* background-color: #9896db; */
    background: url(/static/media/michaelangelo.5179d47a.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    grid-row-start: 2;
    grid-column-start: 4;

    grid-row-end: 3;
    grid-column-end: 5;
  }
  #item-5 {
    /* background-color: #b795bb; */
    background: url(/static/media/gel.02f2407b.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    grid-row-start: 2;
    grid-column-start: 2;

    grid-row-end: 3;
    grid-column-end: 4;
  }
  #item-6 {
    /* background-color: #cb9eb7; */
    background: url(/static/media/wowPoster.d2d0e8db.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 2;
  }
}

.home-container {
    background: url(/static/media/background2.c55727dd.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.home-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.home-name {
    color: #fff;
}

.home-subText {
    color: #fff;
}

.home-social .fab {
    padding: 10px;
    margin-bottom: 20px;
}

.home-social .fab:hover {
    color: #cc33ff;
}

.btn {
    background-color: #cc33ff;
    color: #fff;
    font-size: 1rem;
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.btn:hover {
    border: 1px solid #cc33ff;
    background-color: #77009e;
}

.btn .fas {
    padding-left: 10px;
}

@media screen and (max-width: 460px) {
    .home-name {
        font-size: 1.5rem;
    }

    .home-subText {
        font-size: 1.2rem;
    }
}
.navbar {
    background-color: #010101;
    padding: 5px;
}

.navbar-container {
    display:  flex;
    justify-content: space-between;
    align-items: center;
    width: 70vw;
    margin: 0 auto;
}
.skills-container {
    background-color: #353535;
    color: #fff;
}
.skills-contents {
  /* background-color: red; */
    width: 70vw;
    margin: 5rem auto 0;
    padding-top: 4rem;
}

.skills-contents .header {
    margin-bottom: 10px;
}

.skills-contents .header-bar {
    background-color: #fff;
    margin-bottom: 1.5rem;
}

.skills-avail {
    width: 70vw;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
}

.card {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    margin-bottom: 60px;
}

.card img {
    height: 80px;
    margin-top: 40px;
}

.card-container {
    width: 100%;
    margin-top: 30px;
    padding-top: 30px;
    text-align: center;
}

.card h4 {
    color: #333;
}

.card p {
    color: #333;
    margin-top: 2rem;
    padding: 0 30px;
}

@media screen and (max-width: 460px) {
    .skills-contents {
        /* background-color: red; */
        width: 90vw;
        padding-top: 2rem;
    }

    .skills-contents .header p {
        font-size: .9rem;
    }

    .skills-avail {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 90%;
        margin-bottom: 20px;
    }
}
@keyframes fade-in-left {
    0% {
        transform: translateX(-200px);
            opacity: 0;
    }
    100% {
        transform: translateX(0);
            opacity: 1;
    }
}

.animation-container {
    margin: 0 auto;
    background-color: #333;
    /* height: 100vh; */
    color: #fff;
}

.animation-contents {
  /* background-color: tomato; */
    width: 70vw;
    margin: 0 auto;
    padding: 3rem 0 3rem;
}

.animation-contents .header {
  margin-bottom: 40px;
}

.animation-contents .header-bar {
    background-color: #fff;
}

.animation-contents h5 {
    animation: fade-in-left 1s ease-in-out .5s both;
}

.vid-player {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    animation: fade-in-left 1s ease-in-out 1.5s both;
}

.vid-player .videos {
  margin-bottom: 20px;
}

.vid-player img {
  cursor: pointer;
}

@media screen and (max-width: 460px) {
  .animation-contents {
    /* background-color: tomato; */
    width: 90vw;
    margin: 0 auto;
    padding: 3rem 0 3rem;
  }

  .vid-player {
    width: 98%;
  }
}
@keyframes fade-in-left {
    0% {
        transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.contact-container {
    background-color: #010101;
    margin: 0 auto;
    color: #fff;
}

.contact-contents {
    /* background-color: tomato; */
    width: 70vw;
    margin: 0 auto;
    padding: 3rem 0 3rem;
}

.contact-contents .header-bar {
    background-color: #fff;
}

.contact-contents .header {
    margin-bottom: 40px;
}

.contact-contents h5 {
    animation: fade-in-left 1s ease-in-out 0.5s both;
}

.contact-form {
    display: flex;
    justify-content: space-between;

}

.contact-info {
    background-color: #cc33ff;
    width: 40%;
    padding: 2rem;
    background: url(/static/media/background2.c55727dd.jpg);
}

.contact-icons {
    text-align: center;
}

.contact-social {
    color: #fff;
}

.contact-social .fab {
    padding: 20px;
}

.contact-social .fab:hover {
    color: #cc33ff;
}

.qr-code {
    margin: 30px auto;
}

.form-body {
    /* background: #eee; */
    display: flex;
    justify-content: center;
    color: #eee;
    border-radius: 30px;
    width: 100%;
}

form {
    width: 100%;
    display: flex;
    justify-content: center;
}

form button {
    width: 190px;
    height: 55px;
    border: 0;
    border-radius: 5px;
    background-color: #cc33ff;
    transition: 0.5s linear;
    color: white;
    font-weight: bold;
    /* margin-top: 20%; */
}

form button:hover {
    background-color: unset;
    border: 2px solid #cc33ff;
    transition: 0.5s linear;
}

.formWord {
    width: 70%;
    padding: 2rem 0;
}

.formWord input {
    width: 100%;
    height: 50px;
    margin-bottom: 1rem;
    border: 0;
    margin-top: .1rem;
    padding-left: 3%;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgb(218, 218, 218);
}

.formWord textarea {
    width: 100%;
    margin-bottom: 10%;
    border: 0;
    margin-top: 2%;
    height: 150px;
    color: black;
    box-shadow: 0px 0px 10px 0px rgb(218, 218, 218);
    padding-left: 3%;
}

@media screen and (max-width: 460px) {
    .contact-info {
        display: none;
    }

    .contact-contents {
        /* background-color: tomato; */
        width: 90vw;
        padding: 3rem 0;
    }

    .formWord {
        width: 100%;
        padding: 2rem 0;
    }
}

@media screen and (max-width: 860px) {
    .contact-info {
        display: none;
    }
}
.footer {
    background: #010101;
    color: #eee;
    text-align: center;
    padding: 1rem;
}
