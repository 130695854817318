@keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
            opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
    }
}

.frontend-container {
    background-color: #010101;
    margin: 0 auto;
    color: #fff;
}

.frontend-contents {
    /* background-color: tomato; */
    width: 70vw;
    margin: 0 auto;
    padding: 3rem 0 .2rem;
}

.frontend-contents .header {
    margin-bottom: 10px;
}

.frontend-contents h5 {
    animation: fade-in-left 1s ease-in-out .5s both;
}

.frontend-contents .header-bar {
    background-color: #fff;
}

.gridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    padding: 2rem;
    place-items: center;
    column-gap: 2rem;
    row-gap: 3rem;
    margin-bottom: 0.5rem;
    animation: fade-in-left 1s ease-in-out 1.5s both;
}

.blogCard {
    border-radius: 10px;
    box-shadow: 3px 3px 20px rgba(80, 78, 78, 0.5);
    text-align: center;
    width: 300px;
}

.portImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.titleContent {
    text-align: center;
    z-index: 20;
    width: 100%;
}

.headerThree {
    font-weight: 500;
    letter-spacing: 2px;
    color: #cc33ff;
    padding: 0.5rem 0;
}

hr {
    width: 50px;
    height: 3px;
    margin: 20px auto;
    border: 0;
    background: #d0bb57;
}
.cardInfo {
    width: 100%;
    padding: 0 30px;
    color: #e4e6e7;
    font-size: .8rem;
    line-height: 24px;
    text-align: center;
}

.titleContent {
    text-align: center;
    z-index: 20;
    width: 100%;
}

.tagList {
    display: flex;
    justify-content: space-around;
    padding: .5rem;
}

.tag {
    color: #d8bfbf;
    font-size: 1rem;
}

.utilityList {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
    margin: 1rem 0;
}

.externalLinks {
    color: #fff;
    font-size: 1.6rem;
    padding: 5px 20px;
    background: #cc33ff;
    border-radius: 26px;
    transition: 0.5s;
    font-size: .8rem;
}

.externalLinks:hover {
    background: #8300af;
    color: #fff;
}

@media screen and (max-width: 460px) {
    .frontend-contents {
        /* background-color: tomato; */
        width: 90vw;
    }
}