@keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
            opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
    }
}

.animation-container {
    margin: 0 auto;
    background-color: #333;
    /* height: 100vh; */
    color: #fff;
}

.animation-contents {
  /* background-color: tomato; */
    width: 70vw;
    margin: 0 auto;
    padding: 3rem 0 3rem;
}

.animation-contents .header {
  margin-bottom: 40px;
}

.animation-contents .header-bar {
    background-color: #fff;
}

.animation-contents h5 {
    animation: fade-in-left 1s ease-in-out .5s both;
}

.vid-player {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    animation: fade-in-left 1s ease-in-out 1.5s both;
}

.vid-player .videos {
  margin-bottom: 20px;
}

.vid-player img {
  cursor: pointer;
}

@media screen and (max-width: 460px) {
  .animation-contents {
    /* background-color: tomato; */
    width: 90vw;
    margin: 0 auto;
    padding: 3rem 0 3rem;
  }

  .vid-player {
    width: 98%;
  }
}