.navbar {
    background-color: #010101;
    padding: 5px;
}

.navbar-container {
    display:  flex;
    justify-content: space-between;
    align-items: center;
    width: 70vw;
    margin: 0 auto;
}