@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;600;800&display=swap');

@keyframes fade-in-left {
  0% {
      -webkit-transform: translateX(-200px);
          transform: translateX(-200px);
          opacity: 0;
  }
  100% {
      -webkit-transform: translateX(0);
          transform: translateX(0);
          opacity: 1;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

a {
  text-decoration: none;
  color: #fff;
}

li {
  list-style: none;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.header-text {
  font-weight: 800;
  animation: fade-in-left 1s ease-in-out .5s both;
}

.header-bar {
  background-color: #010101;
  height: 3px;
  width: 150px;
  animation: fade-in-left 1s ease-in-out 1s both;
}
